<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeModal($event)"><ion-icon slot="icon-only" name="arrow-back"></ion-icon></ion-button>
    </ion-buttons>
    <ion-title>Telefonnummer verifizieren</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p *ngIf="isLoggedIn">
    Wir verknüpfen dein schneller-besteller Nutzerkonto mit deiner angegebenen Telefonnummer,
    um Missbrauch zu vermeiden.
  </p>
  <p *ngIf="!isLoggedIn">
    Für To-Go-Bestellungen ist ein Nutzerkonto mit verifizierter Mobilfunknummer
    nötig, um Missbrauch zu vermeiden.
  </p>
  <p>
    Wir haben eine SMS mit einem Code zur Verifikation an <strong><ion-text color="primary">{{ phone }}</ion-text></strong> geschickt. Bitte gib diesen Code hier ein.
  </p>

  <ion-item>
    <ion-label position="floating">Verifikationscode</ion-label>
    <ion-input [(ngModel)]="code"></ion-input>
  </ion-item>

  <div id="captcha" slot="fixed"></div>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" type="submit" expand="block" [disabled]="false" (click)="finishVerification()">verifizieren</ion-button>
  </ion-toolbar>
</ion-footer>
