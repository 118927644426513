import firebase from 'firebase/app';

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, empty, combineLatest, of } from 'rxjs';
import { map, take, shareReplay } from 'rxjs/operators';
import { Moment } from 'moment';
import { cloneDeep } from 'lodash';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentSnapshot,
  QueryFn
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { Table, Product, ProductFilter, IOrder, Location, Order } from '../../shared/models';

import { AuthService } from './auth.service';
import { FiltersService } from './filters.service';
import { AnalyticsService } from './analytics.service';
import { splitTags, getValue } from 'src/app/shared/misc/tools';

export const PRODUCTS_COLLECTION_NAME = 'products';
export const PROFILES_COLLECTION_NAME = 'profiles';
export const TABLES_COLLECTION_NAME = 'tables';
export const ORDERS_COLLECTION_NAME = 'orders';
export const LOCATIONS_COLLECTION_NAME = 'locations';

function docDataWithId<T>(doc: DocumentSnapshot<T>): T {
  if (!doc || !doc.data) return;

  const data: any = doc.data();
  if (!data) return;

  if (doc.id) data.id = doc.id;
  return data;
}

@Injectable({
  providedIn: 'root'
})
export class DbService implements OnDestroy {
  private _productsCollection: AngularFirestoreCollection<Product>;
  private _tablesCollection: AngularFirestoreCollection<Table>;

  private tablesCache$ = new Map<string, Observable<Table>>();
  private productsCache$ = new Map<string, Observable<Product>>();
  private locationProductsCache$ = new Map<string, Observable<Product[]>>();
  private locationTablesCache$ = new Map<string, Observable<Table[]>>();

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private filters: FiltersService,
    private analytics: AnalyticsService,
  ) {
    this._tablesCollection = this.db.collection<Table>(TABLES_COLLECTION_NAME);
    this._productsCollection = this.db.collection<Product>(PRODUCTS_COLLECTION_NAME);
  }

  public getCollection<T>(collectionName: string): AngularFirestoreCollection<T> {
    return this.db.collection<T>(collectionName);
  }

  public get now(): firebase.firestore.Timestamp {
    // check if this timestamp is good enough for synchronity
    return firebase.firestore.Timestamp.now();
  }

  /**
   * Gets a firebase timestamp from a moment object
   * @param m Moment object
   */
  public getTS(m: Moment): firebase.firestore.Timestamp {
    return new firebase.firestore.Timestamp(m.unix(), 0);
  }

  private docUpdates$<T>(doc: AngularFirestoreDocument<T>): Observable<T> {
    if (!doc) throw Error('doc missing');

    return doc.snapshotChanges().pipe(
      map(action => docDataWithId<T>(action.payload as DocumentSnapshot<T>))
    );
  }

  public updates$<T>(collectionName: string, query: QueryFn = null): Observable<T[]> {
    if (!query) return this.collectionUpdates$<T>(this.db.collection<T>(collectionName));
    return this.collectionUpdates$<T>(this.db.collection<T>(collectionName, query));
  }

  private collectionUpdates$<T>(collection: AngularFirestoreCollection<T>): Observable<T[]> {
    if (!collection) throw Error('collection missing');

    try {
      return collection.snapshotChanges().pipe(
        map(actions => actions
          .filter(action => !!action && !!action.payload && !!action.payload.doc && !!action.payload.doc.data())
          .map(action => {
            const
              data: any = action.payload.doc.data(),
              id = action.payload.doc.id;

            if (!data || !id) return null;

            return { ...data, id };
          })
        )
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  public getCurrent$<T>(collection: string, id: string): Observable<T> {
    if (!id) throw Error('id missing');

    return this.docUpdates$<T>(this.db.doc<T>(collection + '/' + id))
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  public getCurrent<T>(collection: string, id: string): Promise<T> {
    if (!id) return Promise.reject('id missing');

    return getValue(this.getCurrent$<T>(collection, id));
  }

  public getDoc<T>(collection: string, id: string): AngularFirestoreDocument<T> {
    return this.getCollection<T>(collection).doc(id);
  }

  public async add(collection: string, document: any): Promise<string> {
    const docRef = await this.getCollection(collection).add(document);
    return docRef.id;
  }

  public async update(collection: string, document: any, overwrite: boolean = true): Promise<string> {
    if (!document || !document.id) throw new Error('no valid entity to update. ID missing.');
    if (overwrite) await this.getCollection(collection).doc(document.id).set(document);
    else await this.getCollection(collection).doc(document.id).update(document);
    return document.id;
  }

  public async addUpdate(collection: string, doc: any): Promise<string> {
    if (!doc) throw new Error('No document given');
    if (!this.auth.currentUserId) throw new Error('Invalid user!');

    if (!doc.id) return this.add(collection, doc);
    return this.update(collection, doc);
  }

  public async delete(collection: string, id: string): Promise<void> {
    if (!id) return Promise.reject(new Error('No location given'));
    return this.getCollection(collection).doc(id).delete();
  }

  //------------------------------------------------------------ Order ----------------------------------------------------------------------------------

  public async addOrder(order: IOrder): Promise<string> {
    if (!order.orderedAt) throw Error('Order incomplete');
    const docRef = await this.getCollection<IOrder>(ORDERS_COLLECTION_NAME).add(order);
    return docRef.id;
  }

  public async updateOrder(order: IOrder, changeSet: Partial<Order>): Promise<void> {
    if (!order.orderedAt) throw Error('Order incomplete');

    await this.getCollection<IOrder>(ORDERS_COLLECTION_NAME).doc(order.id).set(
      changeSet as IOrder,
      { merge: true }
    );
  }

  //------------------------------------------------------------ Location ----------------------------------------------------------------------------------

  public async addUpdateLocation(location: Partial<Location>, logEvent: string = 'updated'): Promise<string> {
    if (!location) throw new Error('No location given');
    if (!this.auth.currentUserId) throw new Error('Not logged in!');

    // TODO: validate input

    if (!location.owners) location.owners = {};
    if (!Object.keys(location.owners).length) location.owners[ this.auth.currentUserId ] = true;

    const result = await this.addUpdate(LOCATIONS_COLLECTION_NAME, location);
    this.analytics.logLocationEdit(location, logEvent);
    return result;
  }

  public async deleteLocation(location: Location): Promise<void> {
    const deletedLocation = cloneDeep(location);
    deletedLocation.deleted = true;
    deletedLocation.origMembers = { ...deletedLocation.members };
    deletedLocation.origManagers = { ...deletedLocation.managers };
    deletedLocation.origOwners = { ...deletedLocation.owners };
    deletedLocation.members = {};
    deletedLocation.managers = {};
    deletedLocation.owners = {};
    await this.update(LOCATIONS_COLLECTION_NAME, deletedLocation);

    this.analytics.logLocationEdit(location, 'deleted');
  }

  //------------------------------------------------------------ Table ----------------------------------------------------------------------------------

  public getTablesForLocation$(locationId: string): Observable<Table[]> {
    if (!locationId) return empty();

    if (!this.locationTablesCache$.has(locationId)) {
      let _tablesCollectionWithRef: AngularFirestoreCollection<Table>;
      _tablesCollectionWithRef = this.db.collection(TABLES_COLLECTION_NAME,
        ref => ref.where('locationId', '==', locationId)
      );

      const tables$ = this.collectionUpdates$<Table>(_tablesCollectionWithRef)
        .pipe(
          map(tables => tables.sort((t1, t2) => t1.name && t1.name.localeCompare(t2.name))),
          shareReplay({ bufferSize: 1, refCount: true }),
        );

      this.locationTablesCache$.set(locationId, tables$);
    }

    return this.locationTablesCache$.get(locationId);
  }

  public getTable$(tableId: string): Observable<Table> {
    if (!tableId) return empty();
    if (!this.tablesCache$.has(tableId)) this.tablesCache$.set(tableId, this.getCurrent$<Table>(TABLES_COLLECTION_NAME, tableId));
    return this.tablesCache$.get(tableId);
  }

  public getTableByCode$(code: string): Observable<Table> {
    if (!code) return empty();
    if (!this.tablesCache$.has(code)) {
      let _tablesCollectionWithRef: AngularFirestoreCollection<Table>;
      _tablesCollectionWithRef = this.db.collection(TABLES_COLLECTION_NAME,
        ref => ref.where('code', '==', code)
      );

      const table$ = this.collectionUpdates$<Table>(_tablesCollectionWithRef)
        .pipe(
          map(tables => tables && tables.length ? tables[ 0 ] : undefined),
          shareReplay({
            refCount: true,
            bufferSize: 1
          })
        );

      this.tablesCache$.set(code, table$);
    }
    return this.tablesCache$.get(code);
  }

  public async getTableByCode(code: string): Promise<Table> {
    const table = await this.getTableByCode$(code).pipe(take(1)).toPromise();
    if (!table) throw 'table not found';
    return table;
  }

  //TODO Error-handling, falls ein db.set fehlschlägt nach X sekunden erneut alle codes setzen, oder codes zurücksetzten und user alert.
  public async refreshTableCodes(tables: Table[]): Promise<string[]> {
    if (!tables.length) throw new Error('no tables given');
    let refreshedIds = await Promise.all(tables.map(table => this.addUpdateTable({ ...table, code: '' }, true)));
    this.analytics.logRefreshTableCodes(tables);
    return refreshedIds;
  }

  public async addUpdateTable(table: Table, batch?: boolean): Promise<string> {
    if (!table) throw new Error('no table given');

    if (!table.code) {
      let code = '';
      do {
        code = [ ...Array(6).fill('.') ].map(() => Math.random().toString(36)[ 2 ]).join('');
        const snapshot = await this._tablesCollection.ref.where('code', '==', code).limit(1).get();
        if (!snapshot.size) {
          table.code = code;
        }
      }
      while (!table.code);
    }

    if (!table.id) {
      const docRef = await this._tablesCollection.add(table);
      this.analytics.logEditTable(table, 'created');
      return docRef.id;
    }

    await this._tablesCollection.doc(table.id).set(table)
    if (!batch) { this.analytics.logEditTable(table, 'updated'); }
    return table.id
  }

  public async deleteTable(table: Table): Promise<any> {
    if (!table.id) return Promise.reject(new Error('No table given'));
    let result = await this._tablesCollection.doc(table.id).delete();
    this.analytics.logEditTable(table, 'updated');
    return result
  }

  //------------------------------------------------------------ Product ----------------------------------------------------------------------------------

  public getProduct$(productId: string): Observable<Product> {
    if (!productId) throw new Error('no product found');
    if (!this.productsCache$.has(productId)) this.productsCache$.set(productId, this.getCurrent$<Product>(PRODUCTS_COLLECTION_NAME, productId));
    return this.productsCache$.get(productId);
  }

  public getProduct(productId: string): Promise<Product> {
    if (!productId) return Promise.reject('no product found');

    return this.getCurrent<Product>(PRODUCTS_COLLECTION_NAME, productId);
  }

  public getAllProductsForLocation$(locationId: string, inactive: boolean = false): Observable<Product[]> {
    if (!locationId) return empty();

    if (!this.locationProductsCache$.has(locationId)) {
      let _productsCollectionWithRef: AngularFirestoreCollection<Product>;
      _productsCollectionWithRef = this.db.collection(
        PRODUCTS_COLLECTION_NAME,
        ref => {
          let query = ref.where('locationId', '==', locationId);
          if (!inactive) query = query.where('active', '==', true);
          return query.orderBy('name');
        }
      );

      const products$ = this.collectionUpdates$<Product>(_productsCollectionWithRef)
        .pipe(
          shareReplay({ bufferSize: 1, refCount: true }),
        );

      this.locationProductsCache$.set(locationId, products$);
    }

    return this.locationProductsCache$.get(locationId);
  }

  public getProductsForLocation$(locationId: string, filterName: string, options?: { inactive?: boolean, tableId?: string }): Observable<Product[]> {
    const { inactive = false, tableId = '' } = options || {};
    return combineLatest(
      this.getAllProductsForLocation$(locationId, inactive),
      this.filters.getFilter$<ProductFilter>(filterName),
      tableId ? this.getTable$(tableId) : of<Table>(null),
    )
      .pipe(
        map(([ products, filter, table ]) => this.filterProducts(products, filter, table)),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
  }

  public hasActiveProducts$(locationId: string): Observable<boolean> {
    return this.getAllProductsForLocation$(locationId)
      .pipe(
        map(locations => !!locations && !!locations.length),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
  }

  private filterProducts(products: Product[], filter: ProductFilter, table?: Table): Product[] {
    if (!filter) return products;
    const { name = '', alcoholic, food, barOnly, tag } = filter;

    return products.filter(product => {
      if (!alcoholic && !!product.alcoholic) return false;
      if (!!food && !product.food) return false;
      if (!barOnly && !!product.barOnly) return false;

      // filter out products that don't match the name filter
      const lowerName = name.toLocaleLowerCase();
      if (
        !product.tags.toLocaleLowerCase().includes(lowerName) &&
        !product.prices.some(price => price.label && price.label.toLocaleLowerCase().includes(lowerName)) &&
        !product.name.toLocaleLowerCase().includes(lowerName) &&
        !product.description.toLocaleLowerCase().includes(lowerName)
      ) return false;

      // filter out products that don't match any tag
      const tagList = splitTags(product.tags);
      if (tag && (!tagList || !tagList.length || !tagList.includes(tag))) return false;

      if (
        // if products should be filtered for a table
        !!table && !!table.productTags && !!table.productTags.length &&
        // filter out all products
        // where their tags don't contain some of the tables tags
        !table.productTags.some(productTag => product.tags.includes(productTag))
      ) return false;
      return true;
    });
  }

  public getTagsForLocation$(locationId: string, filteredTag?: string, filterName?: string, options?: { inactive?: boolean, tableId?: string }): Observable<string[]> {
    const { inactive = false, tableId = '' } = options || {};
    return this.getProductsForLocation$(locationId, filterName, { inactive, tableId })
      .pipe(
        map(products => products
          .map(product => product.tags)
          .map(tags => splitTags(tags))
          .reduce((acc, tags) => ([ ...acc, ...tags ]), [])
          .sort()
        ),
        map(tags => [ filteredTag, ...tags ].filter(tag => !!tag)),
        // sort by product count
        map(tags => {
          const counts: { [ key: string ]: number } = tags.reduce((acc, tag) => ({ ...acc, [ tag ]: (+acc[ tag ] || 0) + 1 }), {});
          return Object.entries(counts)
            .sort(([ , v1 ], [ , v2 ]) => v2 - v1)
            .map(([ tag ]) => tag);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
  }

  public getAdditionsForLocation$(locationId: string, filterName?: string, options?: { inactive?: boolean, tableId?: string }): Observable<string[]> {
    const { inactive = false, tableId = '' } = options || {};
    return this.getProductsForLocation$(locationId, filterName, { inactive, tableId })
      .pipe(
        map(products => products
          .map(product => product.additions)
          .map(additions => Array.isArray(additions) ? additions : splitTags(additions))
          .reduce((acc, additions) => ([ ...acc, ...additions ]), [])
          .sort()
        ),
        map(additions => [ ...new Set(additions) ].sort()),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
  }

  public getTableTagsForLocation$(locationId: string): Observable<string[]> {
    return this.getTablesForLocation$(locationId)
      .pipe(
        map(tables => this.getAllTagsForItems(tables)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
  }

  private getAllTagsForItems(items: (Table | Product)[]): string[] {
    if (!items) return [];

    const counts: { [ key: string ]: number } = items
      .map((item: Table | Product) => splitTags(item.tags))
      .reduce((acc, tags) => ([ ...acc, ...tags ]), [])
      .sort()
      .reduce((acc, tag) => ({ ...acc, [ tag ]: (+acc[ tag ] || 0) + 1 }), {});

    return Object.entries(counts)
      .sort(([ , v1 ], [ , v2 ]) => v2 - v1)
      .map(([ tag ]) => tag);
  }

  public async cloneProduct(product: Product): Promise<string> {
    if (!product) throw new Error('no product given');

    const newProduct = JSON.parse(JSON.stringify(product));
    delete newProduct.id;

    newProduct.name = `Kopie von ${ product.name }`;

    return await this.addUpdateProduct(newProduct);
  }

  public async toggleProductActive(product: Product): Promise<boolean> {
    if (!product) throw new Error('no product given');
    product.active = !product.active;

    await this.addUpdateProduct(product);
    return product.active;
  }

  public async addUpdateProduct(product: Product): Promise<string> {
    if (!product) throw new Error('no product given');

    if (product.prices && product.prices.length) {
      product.prices.forEach(price => price.price = +price.price);
      product.prices = product.prices.filter(price => price && price.price);
    }

    if (product.options && product.options.length) {
      product.options = product.options.filter(option => !!option);
    }

    if (!product.id) {
      const docRef = await this._productsCollection.add(product);
      this.analytics.logEditProduct(product, 'created');
      return docRef.id;
    }

    await this._productsCollection.doc(product.id).set(product);
    this.analytics.logEditProduct(product, 'updated');
    return product.id;
  }

  public async setProductsActive(products: Product[], active: boolean): Promise<void> {
    if (!products) throw new Error('no product given');

    const batch = this.db.firestore.batch();
    products
      .filter(product => product.id && product.id !== 'waiter' && product.active !== active)
      .forEach(product => batch.update(this._productsCollection.doc(product.id).ref, { ...product, active }));
    await batch.commit();
  }

  public async deleteProduct(product: Product): Promise<void> {
    if (!product.id) return Promise.reject(new Error('No product given'));
    await this._productsCollection.doc(product.id).delete();
    this.analytics.logEditProduct(product, 'deleted');
  }

  //------------------------------------------------------------ Files ----------------------------------------------------------------------------------

  public getUrlForPath(path: string): Promise<string> {
    return this.storage.ref(path).getDownloadURL().toPromise();
  }

  public async uploadFile(file: Blob, customMetadata: any = {}): Promise<string> {
    if (!file) throw new Error('No file given');
    if (!this.auth.currentUserId) throw new Error('Not logged in!');

    const filePath = '/images/' + this.db.createId();

    customMetadata[ this.auth.currentUserId ] = true;

    await this.storage.upload(filePath, file, { customMetadata }).snapshotChanges().toPromise()
    return filePath;
  }

  public ngOnDestroy(): void {
  }

}
