import firebase from 'firebase/app';
import { Injectable, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, Subject, combineLatest, ReplaySubject, of } from 'rxjs';
import { map, filter, switchMap, shareReplay, takeUntil, startWith, take, debounceTime } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';

import { Profile, TogoCustomerData } from '../../shared/models';
import { getValue } from '../../shared/misc/tools';

import { AuthService } from './auth.service';
import { DbService } from './db.service';
import { LocationsService } from './locations.service';
import { HelpService } from './help.service';
import { AnalyticsService } from './analytics.service';

export const PROFILES_COLLECTION_NAME = 'profiles';

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements OnDestroy {
  private destroy$: Subject<any> = new Subject();

  private _profiles$: Observable<any[]>;
  private _current$: Observable<Profile>;
  private _currentDisplayName$: Observable<string>;
  private _hasRole$: Observable<boolean>;
  private _isAdmin$: Observable<boolean>;
  private updating: any = {};
  private notifications$Map: { [ key: string ]: Subject<boolean> } = {};
  private userNameValidationRegex = /^[\w -\.äöüß]{3,50}$/i;

  constructor(
    private auth: AuthService,
    private db: DbService,
    private locations: LocationsService,
    private functions: AngularFireFunctions,
    private help: HelpService,
    private storage: Storage,
    private analytics: AnalyticsService,
  ) {
    // Create / update profile if user has none
    this.auth.currentUser$
      .pipe(
        filter(user => !!user && !!user.uid && !user.isAnonymous),
        switchMap(
          user => this.getProfile$(user.uid),
          (user, profile) => [ user, profile ]
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([ user, profile ]: [ firebase.User, Profile ]) => {
        const p1 = JSON.stringify(profile);

        if (!profile) profile = this.profileForUser(user);
        // update outdated profiles
        if (!profile.uid && profile.id === user.uid) profile.uid = profile.id;
        if (!profile.name && user.displayName) profile.name = user.displayName;
        if (!profile.photoURL && user.photoURL) profile.photoURL = user.photoURL;

        const p2 = JSON.stringify(profile);

        if (p1 !== p2) this.addUpdateProfile(profile);
      });
  }

  private profileForUser(user: firebase.User): Profile {
    const profile: Profile = {
      uid: user.uid,
      name: user.displayName || '',
    };

    if (user.photoURL) profile.photoURL = user.photoURL;

    return profile;
  }

  public get phoneNumber(): string {
    return this.auth.currentUser && this.auth.currentUser.phoneNumber;
  }

  public get current$(): Observable<Profile> {
    if (!this._current$) {
      this._current$ = this.auth.currentUser$
        .pipe(
          switchMap(user => (!!user && !!user.uid) ? this.getProfile$(user.uid) : of(null)),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    return this._current$;
  }

  public get currentDisplayName$(): Observable<string> {
    if (!this._currentDisplayName$) {
      this._currentDisplayName$ = combineLatest(
        this.current$.pipe(startWith({} as Profile)),
        this.auth.currentUser$.pipe(startWith(null)),
      )
        .pipe(
          map(([ profile, user ]) => {
            if (!user) return 'Gast';

            if (this.auth.isAnonymousUser) return 'Anonymer Nutzer';

            return profile.name || user.displayName || 'Namenloser Nutzer';
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    return this._currentDisplayName$;
  }

  public get hasRole$(): Observable<boolean> {
    if (!this._hasRole$) {
      this._hasRole$ = this.locations.myLocations$
        .pipe(
          map(locations => locations.some(loc => this.locations.hasRole(loc))),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    return this._hasRole$;
  }

  public get isAdmin$(): Observable<boolean> {
    if (!this._isAdmin$) {
      this._isAdmin$ = this.auth.currentUser$
        .pipe(
          switchMap(user => user ? user.getIdTokenResult() : of(null as firebase.auth.IdTokenResult)),
          map(token => !!token && !!token.claims && !!token.claims.isAdmin),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    return this._isAdmin$;
  }

  public getProfile$(uid: string): Observable<Profile> {
    return this.db.getCurrent$<Profile>(PROFILES_COLLECTION_NAME, uid)
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Returns updates on ALL user profiles.
   *
   * Operation is expensive. Make sure to unsubscribe properly.
   */
  public get profiles$(): Observable<Profile[]> {
    if (!this._profiles$) {
      this._profiles$ = this.db.updates$<Profile>(PROFILES_COLLECTION_NAME)
        .pipe(
          map((profiles: Profile[]) => profiles.filter(profile => profile && !profile.deleted)),
          shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    return this._profiles$;
  }

  public async signUp(email: string, password: string, name: string): Promise<firebase.User> {
    if (typeof name !== 'string' || !this.userNameValidationRegex.test(name)) throw { code: 'noName', message: 'no user name given' };
    if (name === password) throw { code: 'equalNamePass', message: 'password may not equal username' };

    const user = await this.auth.signUp(email, password);

    await this.addUpdateProfile({ ...this.profileForUser(user), name });

    return user;
  }

  public async addUpdateProfile(profile: Profile): Promise<string> {
    if (!profile) throw new Error('no profile given');
    if (!profile.uid) throw new Error('profile has no uid');

    if (this.updating[ profile.uid ]) {
      console.log('update already running.');
      return 'update already running.';
    }

    this.updating[ profile.uid ] = true;

    try {
      await this.db.getCollection<Profile>(PROFILES_COLLECTION_NAME).doc(profile.uid).set(profile);
      delete this.updating[ profile.uid ];
      return profile.uid;
    }
    catch (error) {
      delete this.updating[ profile.uid ];
      throw error;
    };
  }

  public async setDisplayName(name: string): Promise<string> {
    if (!name) throw 'noName';
    if (name.length < 3) throw 'tooShort';
    if (name.length > 50) throw 'tooLong';
    if (!this.userNameValidationRegex.test(name)) throw 'wrongName';

    await this.db.getDoc<Profile>(PROFILES_COLLECTION_NAME, this.auth.currentUserId).set(
      { name } as Profile,
      { merge: true }
    );

    return name;
  }

  public async getTogoInfo(): Promise<TogoCustomerData> {
    const
      user = await this.auth.waitForAuthUser(),
      uid = user && user.uid,
      stored = await this.storage.get(`${ uid }_togo_customer_data`) || {},
      name = user.isAnonymous ? '' : await getValue(this.currentDisplayName$.pipe(debounceTime(300))),
      phone = this.phoneNumber,
      customerData: TogoCustomerData = { name, picUpTs: '', ...stored, phone };

    return customerData;
  }

  public async setTogoInfo(data: TogoCustomerData): Promise<void> {
    const
      user = await this.auth.waitForAuthUser(),
      uid = user && user.uid;

    await this.storage.set(`${ uid }_togo_customer_data`, data);
  }

  public hasPushNotifications$(locationId: string): Observable<boolean> {
    if (!this.notifications$Map[ locationId ]) {
      this.notifications$Map[ locationId ] = new ReplaySubject<boolean>(1);
      this.storage.get(`ordersNotifications.${ locationId }`)
        .then(ordersNotifications => this.notifications$Map[ locationId ].next(!!ordersNotifications))
        .catch(error => {
          this.analytics.logError('notifications could not be fetched from storage');
          console.log('notifications could not be fetched from storage', error)
        });
    }

    return this.notifications$Map[ locationId ];
  }

  public async toggleNotifications(locationId: string): Promise<boolean> {
    if (!locationId) return;

    const ordersNotifications = !await this.storage.get(`ordersNotifications.${ locationId }`);

    const
      token = await this.help.requestDeviceToken(),
      updateOrdersSubscription = this.functions.httpsCallable('updateOrdersSubscription');

    await updateOrdersSubscription({ token, locationId, ordersNotifications }).pipe(take(1)).toPromise();
    await this.storage.set(`ordersNotifications.${ locationId }`, ordersNotifications);
    this.notifications$Map[ locationId ].next(ordersNotifications);

    return ordersNotifications;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
