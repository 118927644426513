import { Component, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Platform, MenuController } from '@ionic/angular';
import { timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LocationsService } from './core/services/locations.service';
import { ProfileService } from './core/services/profile.service';
import { DbService } from './core/services/db.service';
import { HelpService } from './core/services/help.service';
import { AnalyticsService } from './core/services/analytics.service';
import { Location } from './shared/models';
import { getValue } from './shared/misc/tools';
import { SubscriptionHandlingComponent } from './shared/misc/subscription-handling-component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent extends SubscriptionHandlingComponent {
  private updating = false;
  private updateAvailable = false;

  public myLocations: Location[];
  public logos: { [key: string]: string } = {};
  public isAdmin$ = this.profile.isAdmin$;
  public hasRole$ = this.profile.hasRole$;

  constructor(
    private platform: Platform,
    private menu: MenuController,
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private locationsService: LocationsService,
    private profile: ProfileService,
    private db: DbService,
    private help: HelpService,
    private analytics: AnalyticsService,
  ) {
    super();
    this.initializeApp();
  }

  private async initializeApp(): Promise<void> {
    await this.platform.ready();

    this.analytics.logInit();

    this.menu.enable(false, 'mainContent');

    this.manage$(this.locationsService.myLocations$)
      .subscribe(locations => {
        locations.forEach(loc => {
          this.initLogoForLocation(loc);
        });
        this.myLocations = locations;
      });

    this.manage$(this.profile.hasRole$)
      .subscribe(hasRole => hasRole && this.menu.enable(true, 'mainContent'));


    // check if update available possible
    this.manage$(this.swUpdate.available)
      .subscribe(() => this.updateAvailable = true);

    // wait for stable app till update registration
    const isStable = getValue(this.applicationRef.isStable.pipe(debounceTime(250)));
    if (isStable && this.swUpdate.isEnabled) this.manage$(timer(0, 60 * 1000))
      .subscribe(() => {
        this.swUpdate.checkForUpdate();
        // query apply update if available
        if (this.updateAvailable) this.updateApp();
      });
  }

  public async updateApp(): Promise<void> {
    if (this.updating) return;
    this.updating = true;

    try {
      await this.help.showAlert('Eine neue Version von schneller-besteller ist verfügbar. Bitte neu laden!');
      window.location.reload();
    }
    catch(error) {
      if (error) this.help.showError('App konnte nicht aktualisiert werden. Bitte schließe sie und lade sie im Browser erneut.', error);
      else this.help.showToast('Bitte speichere offene Änderungen und lade die App erneut. Wir erinnern dich in 60 Sekunden wieder.');
    }
    this.updating = false;
  }

  public async initLogoForLocation(location: Location): Promise<void> {
    if (!location.logoPath) return;
    const logo = await this.db.getUrlForPath(location.logoPath);
    this.logos[location.id] = logo || 'assets/logo_orange_blue.png';
  }
}
