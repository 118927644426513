import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { AnalyticsService } from '../services/analytics.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _analytics: AnalyticsService;

  constructor(private injector: Injector) { }

  private get analytics(): AnalyticsService {
    if (!this._analytics) this._analytics = this.injector.get(AnalyticsService);
    return this._analytics;
  }

  handleError(error: Error | string) {
    let message = '';
    if (typeof error === 'string') message = error;
    else message = error.message;

    this.analytics.logError(message);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }

}
