import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { shareReplay, map, switchMap, debounceTime, tap } from 'rxjs/operators';

import { Location, Table } from '../../shared/models';
import { splitTags } from '../../shared/misc/tools';

import { DbService } from './db.service';
import { AuthService } from './auth.service';

export const LOCATIONS_COLLECTION_NAME = 'locations';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  private _locations$: Observable<Location[]> = this.initLocations$();
  private _myMemberLocations$: Observable<Location[]> = this.initLocationsForRole$('members');
  private _myManagerLocations$: Observable<Location[]> = this.initLocationsForRole$('managers');
  private _myOwnerLocations$: Observable<Location[]> = this.initLocationsForRole$('owners');
  private _myLocations$: Observable<Location[]> = this.initMyLocations$();

  private locationsCache$ = new Map<string, Observable<Location>>();

  constructor(
    private db: DbService,
    private auth: AuthService
  ) {
  }

  private initLocations$(): Observable<Location[]> {
    return this.db.updates$<Location>(LOCATIONS_COLLECTION_NAME, ref => ref.where('deleted', '!=', true)).pipe(
      map(locations => locations.filter(location => !!location.name)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private initMyLocations$(): Observable<Location[]> {
    return combineLatest([ this._myOwnerLocations$, this._myManagerLocations$, this._myMemberLocations$ ])
      .pipe(
        map(([ l1, l2, l3 ]) => ([ ...l1, ...l2, ...l3 ])),
        map(locations => locations
          .filter(
            // remove duplicates via creating a temp set in factory closure
            ((tempSet) => ({ id }: Location) => !tempSet.has(id) && tempSet.add(id))(new Set)
          )
        ),
        debounceTime(10),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  private initLocationsForRole$(role: 'owners' | 'members' | 'managers'): Observable<Location[]> {
    if (![ 'owners', 'members', 'managers' ].includes(role)) throw new Error('invalid role');

    return this.auth.currentUser$
      .pipe(
        debounceTime(10),
        switchMap(user => user ? this.db.updates$<Location>(
          LOCATIONS_COLLECTION_NAME,
          ref => ref.where(`${ role }.${ user.uid }`, '==', true)
        ) : of([])),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  public get locations$(): Observable<Location[]> {
    return this._locations$;
  }

  public get myLocations$(): Observable<Location[]> {
    return this._myLocations$;
  }

  public getLocation$(locationId: string | Location): Observable<Location> {
    if (!locationId) return of(null);
    if (typeof locationId !== 'string') locationId = locationId.id;
    if (!this.locationsCache$.has(locationId)) this.locationsCache$.set(
      locationId,
      this.db.getCurrent$<Location>(LOCATIONS_COLLECTION_NAME, locationId)
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }))
    );
    return this.locationsCache$.get(locationId);
  }

  public getLocationForTable$(table: string | Table): Observable<Location> {
    if (!table) return of(null);
    const tableId = typeof table === 'string' ? table : table.id;
    if (!this.locationsCache$.has(tableId)) {
      const location$ = typeof table !== 'string' && table.locationId
        ? this.getLocation$(table.locationId)
        : this.db.getTable$(tableId)
          .pipe(switchMap(table => this.getLocation$(table.locationId)));

      this.locationsCache$.set(tableId, location$);
    }
    return this.locationsCache$.get(tableId);
  }

  public getTableTags$(locationId: string): Observable<string[]> {
    return this.db.getTableTagsForLocation$(locationId);
  }

  public async addUpdateLocation(location: Partial<Location>, logEvent: string = 'updated'): Promise<string> {
    return await this.db.addUpdateLocation(location, logEvent);
  }

  // deletes by flag
  public async deleteLocation(location: Location): Promise<void> {
    await this.db.deleteLocation(location);
  }

  public hasRole(location: Location): boolean {
    return this.isOwner(location) || this.isManager(location) || this.isStaff(location);
  }

  private isStaff(location: Location): boolean {
    return !!this.auth.currentUserId && !!location && !!location.members
      && location.members[ this.auth.currentUserId ] === true;
  }

  private isManager(location: Location): boolean {
    return !!this.auth.currentUserId && !!location && !!location.managers
      && location.managers[ this.auth.currentUserId ] === true;
  }

  private isOwner(location: Location): boolean {
    return !!this.auth.currentUserId && !!location && !!location.owners
      && location.owners[ this.auth.currentUserId ] === true;
  }

  public mayExportCovidData(location: Location): boolean {
    return this.isOwner(location) || this.isManager(location);
  }

  public mayDelete(location: Location): boolean {
    return this.isOwner(location);
  }

  public mayEdit(location: Location): boolean {
    return this.isOwner(location) || this.isManager(location);
  }

  public mayEditMembers(location: Location): boolean {
    return this.isOwner(location);
  }

  public mayDelete$(location: Location | string): Observable<boolean> {
    return this.getLocation$(location).pipe(map(location => this.mayDelete(location)));
  }

  public mayEdit$(location: Location | string): Observable<boolean> {
    return this.getLocation$(location).pipe(map(location => this.mayEdit(location)));
  }

  public mayEditMembers$(location: Location | string): Observable<boolean> {
    return this.getLocation$(location).pipe(map(location => this.mayEditMembers(location)));
  }

  public async getLogoUrl(location: Location): Promise<string> {
    return location && location.logoPath && this.db.getUrlForPath(location.logoPath);
  }

  public getLogoUrl$(location: Location | string): Observable<string> {
    return this.getLocation$(location).pipe(switchMap(location => this.getLogoUrl(location)));
  }

  public isServiceDisabled(location: Location, table: Table): boolean {
    if (!table) return false;
    if (!location) return false;
    const disabledTags = splitTags(location.disabledOrdersTableTags);
    return splitTags(table.tags).some(tag => disabledTags.includes(tag));
  }

}
