export const environment = {
  name: 'production',
  production: true,
  firebase: {
    apiKey: 'AIzaSyB43RwIdcmyYS26PpCxiV0aG3gGDsHZQsw',
    authDomain: 'schneller-besteller.firebaseapp.com',
    databaseURL: 'https://schneller-besteller.firebaseio.com',
    projectId: 'schneller-besteller',
    storageBucket: 'schneller-besteller.appspot.com',
    messagingSenderId: '322604788245',
    appId: "1:322604788245:web:2b17f86151044ea8f83c1c",
    measurementId: "G-W7NJ31D33Q",
    vapidKey: 'BOCPE8hCEElR6SpOMarCVJzQbxXfCz6HsktmGWOiwu7A9cFBRkAlPrNS95vYSjkH2H2R6qngvPuJr245KPuoMWg'
  }
};
