import moment, { unitOfTime } from 'moment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { Order } from '../models';

export function wait(ms: number): Promise<void> {
  return new Promise(resolve => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve();
    }, ms);
  });
}

export function appendAndWait(target: HTMLElement, nativeElement: HTMLElement): Promise<void> {
  const
    config = { attributes: true, childList: true },
    waitFor = new Promise<void>(resolve => {
      const observer = new MutationObserver(() => {
        if (target.contains(nativeElement)) {
          observer.disconnect();
          resolve();
        }
      });
      observer.observe(target, config);
    });

  target.appendChild(nativeElement);
  return waitFor;
}

export async function appendHeadLinkWaitForLoad(doc: Document, href: string, linkAs: string): Promise<HTMLLinkElement> {
  const
    link = doc.createElement('link'),
    loaded = new Promise(resolve => link.onload = resolve);

  link.rel = 'preload';
  link.href = href;
  link.as = linkAs;
  link['crossorigin'] = 'anonymous';

  let element = doc.head.appendChild(link);

  if (linkAs === 'style') {
    const link2 = doc.createElement('link');
    link2.rel = 'stylesheet';
    link2.href = href;
    element = doc.head.appendChild(link2);
  }

  await loaded;
  return element;
}

export function nextFrame() {
  return new Promise(resolve => requestAnimationFrame(resolve));
}

export function orderAgeFilter(order: Order, field: string, duration: number, unit: unitOfTime.DurationConstructor = 'minutes'): boolean {
  if (!order || !field || !unit) throw new Error('filter error!');
  duration = +duration;

  if (!order[field] || !order[field].toDate) return false;
  return moment(order[field].toDate()).add(duration, unit).isAfter(moment());
}

export function capitalize([first, ...rest]: string): string {
  return first.toUpperCase() + rest;
}

export function getValue<T>(observable$: Observable<T>): Promise<T> {
  return observable$.pipe(take(1)).toPromise();
}

/**
 * Splits comma separated tags string to an array of tag strings.
 * Each tag string is trimmed and empty tags are removed.
 *
 * @param tags tags string to split up
 * @returns Array of tag strings. Always returns an array. Array may be empty.
 */
export function splitTags(tags: string): string[] {
  return (tags || '').split(/\s*,\s*/).filter(t => !!t);
}

/**
 * Add the base URL to anchor links. Replace youtube links with youtube player.
 *
 * Function defined like Renderer.link of ngx-markdown
 *
 * @param href Link URL
 * @param title Link title (applied als alt)
 * @param text Link text (applied as text or alt)
 */
export function handleMarkdownLinks(href: string, title: string, text: string): string {
  if (href.startsWith('#')) {
    return `<a href="${window.location.pathname}${href}" alt="${title || text}">${text}</a>`;
  }

  if (href.includes('youtube.com')) {
    return `<div class="md-container"><iframe class="md-video" src="${href}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
  }

  return `<a alt="${title}" href="${href}">${title || text}</a>`;
}
