import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { take, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { LocationsService } from '../services/locations.service';
import { getValue } from '../../shared/misc/tools';

@Injectable({
  providedIn: 'root'
})
export class PrivilidgedGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private locations: LocationsService,
    private router: Router,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    await this.auth.getUser$().pipe(take(1)).toPromise();

    if (this.auth.isLoggedIn) {
      const locationId = route.paramMap.get('locationId');
      if (locationId === 'new' && route && route.url && route.url[0].path === 'location' && route.url[2].path === 'edit') return true;

      const locationIds = await getValue(this.locations.myLocations$.pipe(
        map(locations => locations.map(location => location && location.id))
      ));

      if (locationIds && locationIds.includes(locationId)) return true;
    }

    return this.router.parseUrl('/home');
  }

}
