import { OnDestroy, Component } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// let SUPER_INDEX = 0;

@Component({ selector: 'subscription-handling-base', template: '' })
export class SubscriptionHandlingComponent implements OnDestroy {
  public readonly destroy$: Subject<boolean> = new Subject();
  // private idx: number;

  // constructor() {
  //   this.idx = SUPER_INDEX++;
  //   console.log('create component', this.idx);
  // }

  public ngOnDestroy(): void {
    // console.log('destroy component', this.idx);
    // SUPER_INDEX--;
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public manage$<T>(obs$: Observable<T>): Observable<T> {
    return obs$.pipe(takeUntil(this.destroy$));
  }
}
