import firebase from 'firebase/app';
import moment from 'moment';
import 'moment-duration-format';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { IOrder, Location, Table, Product, OrderHistory, OrderHistoryLogEntry } from '../../shared/models';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(public analytics: AngularFireAnalytics) {
    this.init();
  }

  private async init(): Promise<void> {
    this.analytics.setAnalyticsCollectionEnabled(await firebase.analytics.isSupported());
  }

  private get now(): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.now();
  }

  private logEvent(key: string, log: { [ key: string ]: any } = {}): void {
    const flatLog = Object.entries(log).reduce((acc, [ key, value ]) => {
      if (!(
        value instanceof firebase.firestore.Timestamp ||
        typeof value === 'string' ||
        typeof value === 'number'
      )) {
        value = JSON.stringify(value);
      }

      return { ...acc, [ key ]: value };
    }, {});

    this.analytics.logEvent(key, {
      ...flatLog,
      environment: environment && environment.name || '',
      at: this.now,
    });
  }

  public logInit(): void {
    this.logEvent('app_initialized');
  }

  public logLogin(method: string) {
    // gtag.js events https://developers.google.com/gtagjs/reference/event
    this.logEvent('login', { method });
  }

  public logSignup(method: string) {
    // gtag.js events https://developers.google.com/gtagjs/reference/event
    this.logEvent('sign_up', { method });
  }

  private getChronicEntry(order: IOrder, entry: OrderHistory): OrderHistoryLogEntry {
    const
      from = moment(order.history[ 0 ].time.toDate()),
      to = moment(entry.time.toDate()),
      diff = to.diff(from),
      durationString = moment.duration(diff, 'milliseconds').format('hh:mm:ss', { trim: false });

    return { state: order.state, duration: diff, durationtext: durationString };
  }

  public logOrder(order: IOrder): void {
    const
      chronic = order.history.map(entry => this.getChronicEntry(order, entry)),

      log = {
        togo: !!order.togo,
        customerTakeawayTime: order.customerTime || null,
        togoOrderNumber: order.togoOrderNumber || '',
        product: order.productId,
        productName: order.product ? order.product.name : '',
        price: order.price,
        location: order.locationId,
        locationName: order.location ? order.location.name : '',
        table: order.table ? order.table.id : '',
        tableName: order.table ? order.table.name : '',
        tableCode: order.table ? order.table.code : '',
        orderComment: order.comment,
        tags: order.product ? order.product.tags : '',
        options: order.product ? order.product.options : '',
        history: chronic,
        // TODO: add customer to event log. use client secret to identify customer to detatch analytics data from customer account (GDPR)
        // customer: order.guestId,
      },

      stateMap = {
        ordered: 'order_placed',
        canceled: 'order_canceled',
        added: 'product_added',
        removed: 'product_removed',
        accepted: 'order_accepted',
        served: 'order_served',
        finished: 'order_finished',
        complained: 'order_complained',
        validated: 'order_validated',
      },

      lastStateChange = chronic.slice(-1)[ 0 ];

    if (lastStateChange) {
      log[ 'lastStateOrderTime' ] = lastStateChange.duration;
      log[ 'lastStateOrderTimeText' ] = lastStateChange.durationtext;
    }

    this.logEvent(stateMap[ order.state ] || 'order_unknown', log);

    // gtag.js events https://developers.google.com/gtagjs/reference/event
    const
      gtagProduct = {
        value: order.price,
        currency: 'EUR',
        items: order.product,
      },
      gtagOrder = {
        ...gtagProduct,
        tax: Number((order.price * 0.19).toFixed(2)),
        transaction_id: order.id,
      };

    if (!order.isAbstract) {
      if (order.state == 'added') this.logEvent('add_to_cart', gtagProduct);
      if (order.state == 'ordered') this.logEvent('purchase', gtagOrder);
      if (order.state == 'removed') this.logEvent('remove_from_cart', gtagProduct);
      if (order.state == 'canceled') this.logEvent('refund', gtagOrder);

      // add additional togo event for easier tracking
      if (order.state == 'ordered' && !!order.togo) this.logEvent('togo_placed');
    }
  }

  public logWaiterCall(order: IOrder): void {
    const log: { [ key: string ]: any } = {
      location: order.locationId,
      locationName: order.location ? order.location.name : '',
      table: order.table ? order.table.id : '',
      tableName: order.table ? order.table.name : '',
      tableCode: order.table ? order.table.code : '',
    };

    if (order.comment) log.comment = order.comment;
    if (order.options) order.options.forEach(option => log[ 'option_' + btoa(option) ] = option);

    this.logEvent('waiter_called', log);
  }

  public logTableView(location: Location, table: Table): void {
    const log = {
      location: location.id,
      locationName: location.name,
      table: table.id,
      tableName: table.name,
      tableCode: table.code,
    };

    this.logEvent('order_tableView', log);
  }

  public logOrderPage(location: Location): void {
    const log = {
      location: location.id,
      locationName: location.name,
      disabledOrdersTableTags: location.disabledOrdersTableTags,
    };

    this.logEvent('oderPage_shown', log);
  }

  public logOrdersActivationToggled(location: Location, tag: string, enabled: boolean): void {
    const log = {
      location: location.id,
      locationName: location.name,
      tag, enabled
    };

    this.logEvent('ordersActivation_toggled', log);
  }

  public logLocationCreate(location: Partial<Location>): void {
    const log = {
      locationCity: location.city,
      locationAddress: location.address,
      locationCountry: location.country,
      locationZip: location.zipcode,
      locationName: location.name,
    };

    this.logEvent(`location_${ event }`, log);
  }

  public logLocationEdit(location: Partial<Location>, event: string): void {
    const log = {
      location: location.id,
      locationName: location.name,
    };

    this.logEvent(`location_${ event }`, log);
  }

  public logOpenTableFromHome(table: Table): void {
    const log = {
      location: table.locationId,
      tableName: table.name,
    };

    this.logEvent('table_CodeOpened', log);
  }

  public logTableLogoClick(table: Table): void {
    const log = {
      location: table.locationId,
      tableName: table.name,
    };

    this.logEvent('table_LogoClick', log);
  }

  public logTableSMClick(table: Table, channel: string): void {
    const log = {
      location: table.locationId,
      tableName: table.name,
      channel: channel,
    };

    this.logEvent('table_socialMediaClick', log);
  }

  public logSMClick(channel: string): void {
    const log = {
      channel: channel,
    };

    this.logEvent('socialMediaClick', log);
  }

  public logTableSearch(table: Table, text: string, tags: string[]): void {
    const log = {
      location: table.locationId,
      tableName: table.name,
      searchText: text,
      showedTags: tags,
    };

    this.logEvent('table_Searched', log);

    // gtag.js events https://developers.google.com/gtagjs/reference/event
    this.logEvent('search', { search_term: text });
  }

  public logEditTable(table: Table, event: 'deleted' | 'created' | 'updated'): void {
    const log = {
      location: table.locationId,
      tableName: table.name,
    };

    this.logEvent(`table_${ event }`, log);
  }

  public logRefreshTableCodes(tables: Table[]): void {
    const log = { location: tables[ 0 ].locationId };

    this.logEvent('table_CodesRefreshed', log);
  }

  public logEditProduct(product: Product, event: 'deleted' | 'created' | 'updated'): void {
    const log = {
      location: product.locationId,
      productName: product.name,
      food: product.food,
      barOnly: product.barOnly,
      alcohol: product.alcoholic,
      tags: product.tags,
      prises: product.price,
    };

    this.logEvent(`product_${ event }`, log);
  }

  public logError(message: string, fatal: boolean = false): void {
    // gtag.js events https://developers.google.com/gtagjs/reference/event
    const log = { message, fatal };

    this.logEvent('exception', log);
  }

  public logLandingPageVisit(campaign: string): void {
    this.logEvent(`visit_landing_${ campaign }`);
  }

  public logLandingPageMail(campaign: string): void {
    this.logEvent(`clickmail_${ campaign }`);
  }
}
