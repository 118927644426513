import firebase from 'firebase/app';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, Subject, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, takeUntil, merge, debounceTime } from 'rxjs/operators';

import { getValue } from '../../shared/misc/tools';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private user$: Observable<firebase.User>;
  private user: firebase.User = null;
  private destroy$: Subject<any> = new Subject();
  private roles$: Observable<string[]>;
  private phoneVerificationPending$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private phoneConfirmationTempResult: firebase.auth.ConfirmationResult;
  private phoneVerificationTempId: string;
  private updatedUser$: ReplaySubject<firebase.User> = new ReplaySubject(1);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private analytics: AnalyticsService,
  ) {
    this.angularFireAuth.useDeviceLanguage();

    this.user$ = this.angularFireAuth.authState.pipe(
      merge(this.updatedUser$),
      debounceTime(20),
    );

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async user => {
        this.user = user;
        try {
          await this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        }
        catch (error) {
          console.log('local persistence not possible', error)
        }

        // Uncomment for display user updates
        // if (user) {
        //   const { isAnonymous, uid, email, emailVerified, phoneNumber } = user;
        //   console.log({ uid, isAnonymous, email, emailVerified, phoneNumber });
        // }

        // Uncomment to update admins
        // if (user) this.initAdmins();
      });

    this.roles$ = this.user$
      .pipe(
        map(user => {
          const roles: string[] = [];
          if (!user || this.isAnonymousUser) roles.push('anonymous');

          if (this.isAuthenticated && !this.isVerified) roles.push('unverified');

          if (this.isVerified) {
            roles.push('user');
          }

          return roles;
        })
      );
  }

  async initAdmins() {
    if (!this.currentUser) return;
    const token = await this.currentUser.getIdTokenResult();
    if (!token || !token.claims || !token.claims.isAdmin) return console.log('kein admin');

    const
      setAdmin = this.functions.httpsCallable('setAdmin'),
      // Set this to true to enable someones admin state.
      // Set to false to disable admin state.
      state = true;

    let uid: string;
    // uid = 'di1PggwyPldjmuNj7eSue7A31io1'; // dave rc
    // uid = 'KDen1eIydjMok4N83Ch2qK7mRMy1'; // daniel rc
    // uid = '4LeAy9KXpabT7maWbeoqg4sc6EK2'; // ben rc
    // uid = 'm9i9lemU8tOBlVrmCh9sJhzid9I2'; // jonas rc
    // uid = 'NzzZhmeIbnOwoei6tBqzJ1ujgUH3'; // dave dev
    // uid = 'Kli7fdENKWhPlShkcQVnRiNzel62'; // ben dev
    // uid = 'AhsN5prhmDSN0sovGva3DZrITiH3'; // jonas dev
    if (uid) {
      await getValue(setAdmin({ uid, state }));
      console.log('Set user to admin finished', { uid, state });
    }
  }

  public async anonymousLogin(): Promise<firebase.User> {
    return this.angularFireAuth.signInAnonymously()
      .then(result => {
        console.log('anonymous login successful', result);
        return result.user;
      })
      .catch(error => {
        console.log('anonymous login failed', error);
        return null;
      });
  }

  /**
   * Transfer the orders of the given user to the currently logged in user.
   *
   * Call this after successful login with a new user.
   *
   * @param oldUser Old user ID
   */
  private async transferOrders(oldUser: string): Promise<void> {
    if (oldUser) {
      const transferOrders = this.functions.httpsCallable('transferOrders');
      await getValue(transferOrders({ oldUser }));
      console.log('orders transfered');
    }
  }

  public async loginWithGoogle(): Promise<firebase.User> {
    const
      provider = new firebase.auth.GoogleAuthProvider(),
      oldUser = this.currentUserId,
      credential = await this.angularFireAuth.signInWithPopup(provider);

    this.analytics.logLogin('google');
    await this.transferOrders(oldUser);

    return credential.user;
  }

  public async loginWithPhoneStart(buttonId: string, phone: string): Promise<boolean> {
    // test phone number configured in firebase backend
    // phone = '+1 555-555-1337';
    if (!phone) throw new Error('no phone number given!');

    if (!phone.startsWith('00') && !phone.startsWith('+')) {
      if (phone.startsWith('0')) phone = phone.slice(1);
      phone = `+49${ phone }`;
    }

    const
      recaptchaVerifier = new firebase.auth.RecaptchaVerifier(buttonId, { 'size': 'invisible' }),
      user = await this.waitForAuthUser(),
      { phoneNumber } = user;

    if (phone === phoneNumber) return false;

    this.phoneVerificationPending$.next(true);
    if (this.isLoggedIn) {
      if (phoneNumber) {
        const provider = new firebase.auth.PhoneAuthProvider();
        this.phoneVerificationTempId = await provider.verifyPhoneNumber(phone, recaptchaVerifier);
      }
      else this.phoneConfirmationTempResult = await user.linkWithPhoneNumber(phone, recaptchaVerifier);
    }
    else this.phoneConfirmationTempResult = await this.angularFireAuth.signInWithPhoneNumber(phone, recaptchaVerifier);

    return true;
  }

  public get isPhoneVerificationPending$(): Observable<boolean> {
    return this.phoneVerificationPending$.asObservable();
  }

  public async loginWithPhoneConfirm(confirmationCode: string): Promise<void> {
    if (!this.phoneConfirmationTempResult && !this.phoneVerificationTempId) throw new Error('Can\'t finish verification. Not started');
    if (!confirmationCode) throw new Error('no confirmation code given');
    // test sms confirmation code configured in firebase backend
    // confirmationCode = '421337';

    const oldUser = this.currentUserId;

    // confirm new number and log in for new user or user without number
    if (this.phoneConfirmationTempResult) {
      await this.phoneConfirmationTempResult.confirm(confirmationCode);
    }
    // update phone number on existing user
    else {
      const credential = firebase.auth.PhoneAuthProvider.credential(this.phoneVerificationTempId, confirmationCode)
      await this.currentUser.updatePhoneNumber(credential);
      await this.currentUser.reload();
      this.updatedUser$.next(await this.angularFireAuth.currentUser);
    }

    // transfer orders if any
    await this.transferOrders(oldUser);
  }

  public async loginWithPhoneCancel(): Promise<void> {
    this.phoneVerificationPending$.next(false);
  }

  // TODO: use this when account linking will be implemented.
  // public async loginWithGoogle(): Promise<firebase.User> {
  //   const
  //     provider = new auth.GoogleAuthProvider(),
  //     credential = await this.loginOrSignUp(provider);
  //   return credential.user;
  // }

  // private loginOrSignUp(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
  //   if (firebase.auth().currentUser) return firebase.auth().currentUser.linkWithPopup(provider);
  //   return this.angularFireAuth.auth.signInWithPopup(provider);
  // }

  public async loginWithPassword(email: string, password: string): Promise<firebase.User> {
    const
      oldUser = this.currentUserId,
      credential = await this.angularFireAuth.signInWithEmailAndPassword(email, password);

    this.analytics.logLogin('password');
    await this.transferOrders(oldUser);

    return credential.user;
  }

  public async signUp(email: string, password: string): Promise<firebase.User> {
    if (password.length < 8) throw 'Das Passwort muss mindestens 8 Zeichen lang sein.';

    if (!this.currentUser) await this.anonymousLogin();

    const
      credential = firebase.auth.EmailAuthProvider.credential(email, password),
      user = await this.angularFireAuth.currentUser,
      userCred = await user.linkWithCredential(credential);

    if (!userCred) throw 'Fehler bei Registrierung';

    await this.startVerification();

    this.analytics.logLogin('password');
    console.log('sign up successful', userCred.user);
    return userCred.user;
  }

  public async startVerification(): Promise<void> {
    try {
      const user = await this.angularFireAuth.currentUser;
      await user.sendEmailVerification();
      console.log('mail verification started');
    }
    catch (error) {
      console.log('mail verification failed', error);
    }
  }

  public async startPasswordReset(email?: string): Promise<void> {
    if (!email) email = (await this.angularFireAuth.currentUser).email;
    if (!email) return console.log('no email given.');
    return this.angularFireAuth.sendPasswordResetEmail(email)
      .then(() => console.log('password reset started'))
      .catch(error => console.log('password reset failed', error));
  }

  public async updatePassword(old: string, password: string): Promise<void> {
    const user = this.currentUser;

    if (!user) throw 'Nicht eingeloggt';
    if (old === password) throw 'Passwort gleich';
    if (password.length < 8) throw 'Das Passwort muss mindestens 8 Zeichen lang sein.';

    const credential = firebase.auth.EmailAuthProvider.credential(user.email, old);

    await user.reauthenticateAndRetrieveDataWithCredential(credential);

    return (await this.angularFireAuth.currentUser).updatePassword(password);
  }

  public logout(): Promise<void> {
    return this.angularFireAuth.signOut();
  }

  /**
   * @deprecated Use currentUser$ getter instead.
   */
  public getUser$() {
    return this.user$;
  }

  public getRoles$() {
    return this.roles$;
  }

  public async waitForAuthUser(): Promise<firebase.User> {
    if (this.isAuthenticated) return this.user;
    this.user = await this.anonymousLogin();
    return this.user;
  }

  // Returns true if user is logged in
  get isAuthenticated(): boolean {
    return this.user !== null;
  }

  // Anonymous User
  get isAnonymousUser(): boolean {
    return this.isAuthenticated && this.user.isAnonymous;
  }

  // Returns true if user is logged in
  get isLoggedIn(): boolean {
    return this.isAuthenticated && !this.isAnonymousUser;
  }

  // Returns true if user is logged in
  get isVerified(): boolean {
    return this.isLoggedIn && this.user.emailVerified;
  }

  // Returns current user data
  get currentUser(): firebase.User {
    return this.isAuthenticated ? this.user : null;
  }

  // Returns
  get currentUser$(): Observable<firebase.User> {
    return this.user$;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.isAuthenticated ? this.user.uid : '';
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
