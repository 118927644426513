import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Filters } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private _filters$ = new Map<string, BehaviorSubject<any>>();

  constructor() { }

  private _getFilter$<T extends Filters>(filterName: string, defaultFilter: T = {} as T): BehaviorSubject<T> {
    if (!this._filters$.has(filterName)) this._filters$.set(filterName, new BehaviorSubject(defaultFilter));
    return this._filters$.get(filterName);
  }

  public getFilter$<T extends Filters>(filterName: string, defaultFilter: T = {} as T): Observable<T> {
    if (!filterName) return of(null);
    return this._getFilter$(filterName, defaultFilter);
  }

  public clearFilter(filterName: string): void {
    this._getFilter$(filterName).next(null);
  }

  public getFilter<T extends Filters>(filterName: string): T {
    return this._getFilter$<T>(filterName).getValue();
  }

  public updateFilter<T extends Filters>(filterName: string, filter: Partial<T>): void {
    this._getFilter$(filterName).next({ ...this.getFilter(filterName), ...filter });
  }

  public toggleBooleanField(filterName: string, fieldName: string): void {
    const filter = this.getFilter(filterName) || {};
    filter[fieldName] = !filter[fieldName];
    this._getFilter$(filterName).next(filter);
  }

  public removeField(filterName: string, fieldName: string): void {
    const filter = this.getFilter(filterName) || {};
    if (filter.hasOwnProperty(fieldName)) delete filter[fieldName];
    this._getFilter$(filterName).next(filter);
  }

  public hasField(filterName: string, fieldName: string): boolean {
    const filter = this.getFilter(filterName) || {};
    return filter.hasOwnProperty(fieldName);
  }

}
