import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { PrivilidgedGuard } from './core/guards/privilidged.guard';
import { ManagersGuard } from './core/guards/managers.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(module => module.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginPageModule),
  },
  {
    path: 'location/:locationId/edit',
    loadChildren: () => import('./pages/add-edit-location/add-edit-location.module').then(module => module.AddEditLocationPageModule),
    canActivate: [ PrivilidgedGuard ]
  },
  {
    path: 'location/:locationId/orders',
    loadChildren: () => import('./pages/orders/orders.module').then(module => module.OrdersPageModule),
    canActivate: [ PrivilidgedGuard ]
  },
  {
    path: 't/:tableId',
    loadChildren: () => import('./pages/table/table.module').then(module => module.TablePageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(module => module.ProfilePageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(module => module.FaqPageModule),
  },
  {
    path: 'faq-guests',
    loadChildren: () => import('./pages/faq-guests/faq-guests.module').then(module => module.FaqGuestsPageModule),
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(module => module.LegalPageModule),
  },
  {
    path: 'location/:locationId',
    loadChildren: () => import('./pages/location-details/location-details.module').then(module => module.LocationDetailsPageModule),
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(module => module.TermsPageModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(module => module.PrivacyPageModule),
  },
  {
    path: 'corona-digitale-speisekarte',
    loadChildren: () => import('./pages/landingpage-digital-menu/landingpage-digital-menu.module').then(module => module.LandingpageDigitalMenuPageModule),
  },
  {
    path: 't/:tableId/check-in',
    loadChildren: () => import('./pages/check-in/check-in.module').then(module => module.CheckInPageModule),
  },
  {
    path: 'location/:locationId/covid-export',
    loadChildren: () => import('./pages/covid-export/covid-export.module').then(module => module.CovidExportPageModule),
    canActivate: [ ManagersGuard ]
  },
  {
    path: 'fresh-features',
    loadChildren: () => import('./pages/fresh-features/fresh-features.module').then(module => module.FreshFeaturesPageModule),
  },
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [ RouterModule.forRoot(routes, routingConfiguration) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
