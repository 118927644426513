import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

import { getValue } from 'src/app/shared/misc/tools';

import { AuthService } from '../services/auth.service';
import { LocationsService } from '../services/locations.service';

@Injectable({
  providedIn: 'root'
})
export class ManagersGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private locations: LocationsService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    // wait for a user to be initialized
    await getValue(this.auth.currentUser$);

    const
      locationId = route.paramMap.get('locationId'),
      location = await getValue(this.locations.getLocation$(locationId));

    return this.locations.mayExportCovidData(location) || this.router.parseUrl('/home');
  }

}
