import { Injectable } from '@angular/core';
import { Router, UrlTree, CanActivate } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  public async canActivate(): Promise<boolean | UrlTree> {
    await this.auth.getUser$().pipe(take(1)).toPromise();
    return this.auth.isLoggedIn || this.router.parseUrl('/home');
  }

}
