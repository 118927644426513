import { Component, HostListener, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { mapTo } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { HelpService } from '../../services/help.service';
import { SubscriptionHandlingComponent } from 'src/app/shared/misc/subscription-handling-component';

export interface PhoneLoginResult {
  data: {
    success: boolean;
  }
}

@Component({
  selector: 'app-phone-login-modal',
  templateUrl: './phone-login-modal.component.html',
  styleUrls: ['./phone-login-modal.component.scss'],
})
export class PhoneLoginModalComponent extends SubscriptionHandlingComponent implements OnInit {
  @Input()
  public phone: string;

  @Input()
  public help: () => HelpService;

  public isLoggedIn = false;
  public code = '';

  constructor(
    private modalCtrl: ModalController,
    private auth: AuthService,
  ) {
    super();
    this.manage$(this.auth.currentUser$.pipe(mapTo(this.auth.isLoggedIn)))
      .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);
  }

  public ngOnInit(): void {
    this.loginWithPhone();
  }

  private async loginWithPhone() {
    if (!this.phone) throw new Error('no phone number given!');

    try {
      // TODO: remove. dev number for testing.
      // this.phone = '+15555551338';
      const started = await this.auth.loginWithPhoneStart('captcha', this.phone);
      if (!started) throw new Error('verification could not be started');
    }
    catch (error) {
      if (error) this.help().showError('Die SMS mit dem Verifikationscode konnte nicht verschickt werden. Bitte versuche es erneut.', error);
    }
  }

  /**
   * called from view to finish login.
   *
   * @param code verification code
   */
  public async finishVerification(): Promise<void> {
    // TODO: remove. code for development
    // this.code = '421337';
    try {
      if (!this.code) throw new Error('verification code is empty');
      await this.auth.loginWithPhoneConfirm(this.code);
      this.dismiss(true);
    }
    catch (error) {
      if (error) this.help().showError('Verifikation konnte nicht abgeschlossen werden. Bitte versuche es erneut.', error);
    }
  }

  @HostListener('window:popstate', ['$event'])
  public async closeModal(event: Event): Promise<void> {
    if (event) event.stopPropagation();
    this.dismiss();
  }

  private async dismiss(success = false): Promise<void> {
    const modal = await this.modalCtrl.getTop();
    if (modal) modal.dismiss({ success });
  }

  public verify(): void {
    this.loginWithPhone();
  }
}
