import * as Hammer from 'hammerjs';
import moment from 'moment';
import { environment } from '../environments/environment';

import { NgModule, Injectable, SecurityContext } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MarkdownModule } from 'ngx-markdown';
import { MomentModule } from 'ngx-moment';
import 'moment/locale/de';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule, AngularFirestore, SETTINGS } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule, CONFIG, APP_NAME } from '@angular/fire/analytics';
import { JoyrideModule } from 'ngx-joyride';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

// the second parameter 'fr' is optional
registerLocaleData(localeDe, 'de');
moment.locale('de-DE');

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    MomentModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient, sanitize: SecurityContext.NONE }),
    JoyrideModule.forRoot(),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFirestore,
    { provide: SETTINGS, useValue: {} },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({enabled: environment.production || location.hostname.includes('schneller-besteller-dev.firebaseapp.com')}),
    },
    {
      provide: CONFIG,
      useValue: {
        anonymize_ip: true,
      }
    },
    {
      provide: APP_NAME,
      useValue: environment.firebase.projectId
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
