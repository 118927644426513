<ion-app>
  <ion-split-pane content-id="mainContent" when="false">
    <ion-menu content-id="mainContent" *ngIf="hasRole$ | async">
      <ion-header>
        <ion-toolbar>
          <ion-title>Deine Gastronomien</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list lines="none" class="nav-list">
          <ion-menu-toggle auto-hide="false" *ngFor="let loc of myLocations">
            <ion-item>
              <ion-avatar slot="start">
                <img *ngIf="logos[loc.id] as logo" [src]="logo">
              </ion-avatar>
              <ion-label>{{ loc.name }}</ion-label>
              <ion-button fill="clear" slot="end" [routerDirection]="'forward'" [routerLink]="['location', loc.id, 'edit']">
                <ion-icon slot="icon-only" name="build-outline"></ion-icon>
              </ion-button>
              <ion-button fill="clear" slot="end" [routerDirection]="'forward'" [routerLink]="['location', loc.id, 'orders']">
                <ion-icon slot="icon-only" name="wine-sharp"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-toggle auto-hide="false">
              <ion-button [routerLink]="['location', 'new', 'edit', 'tabs', 'basics']" routerDirection="forward">
                Gastronomie anlegen
                <ion-icon name="add" slot="start"></ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>

    </ion-menu>
    <ion-router-outlet id="mainContent"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
