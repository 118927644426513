import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Sentry from '@sentry/browser';
import { FormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { GlobalErrorHandler } from './error-handler/error-handler';
import { IonicModule } from '@ionic/angular';
import { PhoneLoginModalComponent } from './components/phone-login-modal/phone-login-modal.component';

// develop sentry url
let dsn = 'https://04afd9a7789a424d817b1002da900a83@o392190.ingest.sentry.io/5239360';

if (environment.production) {
  // production sentry url
  dsn = 'https://de81b4f7378a4eaca576d22ee7ac1a90@o392190.ingest.sentry.io/5253158';
}

if (window.location.hostname == "localhost") {
  // local development sentry url
  dsn = 'https://66b0d89b0f874ed3afb309889b2210b0@o392190.ingest.sentry.io/5253165';
}

Sentry.init({ dsn });

@NgModule({
  declarations: [
    PhoneLoginModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    }
  ],
  
})
export class CoreModule { }
