import { firebase } from '@firebase/app';
import '@firebase/messaging';

import { Injectable } from '@angular/core';
import { ToastController, AlertController, ModalController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

import { environment } from '../../../environments/environment';
import { Toast } from '../../shared/models';
import { PhoneLoginModalComponent, PhoneLoginResult } from '../components/phone-login-modal/phone-login-modal.component';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
  ) {
    this.initPushMessageServiceWorker();
  }

  public async initPushMessageServiceWorker(): Promise<void> {
    if (typeof navigator.serviceWorker === 'undefined') return;
    const registration = await navigator.serviceWorker.ready;

    // Don't crash an error if messaging not supported
    if (!firebase.messaging.isSupported()) {
      return;
    }

    const messaging = firebase.messaging();

    // Register the Service Worker
    messaging.useServiceWorker(registration);

    // Initialize your VAPI key
    messaging.usePublicVapidKey(
      environment.firebase.vapidKey
    );

    // Optional and not covered in the article
    // Listen to messages when your app is in the foreground
    messaging.onMessage((payload) => {
      console.log(payload);
    });
    // Optional and not covered in the article
    // Handle token refresh
    messaging.onTokenRefresh(() => {
      messaging.getToken().then(
      (refreshedToken: string) => {
        console.log(refreshedToken);
      }).catch((err) => {
        console.error(err);
      });
    });
  }

  public async requestDeviceToken(): Promise<string> {
    if (!('Notification' in window) || !firebase.messaging.isSupported()) {
      return;
    }
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();

      return await messaging.getToken();
    } catch (err) {
      this.showToast('Keine Freigabe für Push-Notifications erteilt. Bitte prüfe deine Browsereinstellungen.')
    }
  }

  public showAlert(message: string, header: string = 'Bitte bestätigen', oktext: string = 'Ok'): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const dialog = await this.alertCtrl.create({
        header, message,
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
            handler: () => reject(false)
          },
          {
            text: oktext,
            handler: () => resolve(true)
          }
        ]
      });

      dialog.present();
    });
  }

  public showError(message: string, error: null, header: string = 'Fehler'): Promise<boolean> {
    console.log('ERROR:', message, error);

    return new Promise(async resolve => {
      const dialog = await this.alertCtrl.create({
        header, message,
        backdropDismiss: true,
        buttons: [
          {
            text: 'Ok',
            handler: () => resolve(true)
          }
        ]
      });

      dialog.present();
    });
  }

  public async showToast(message: string, config?: Toast): Promise<void> {
    const toastConfig: ToastOptions = {
      message,
      position: 'top',
      // TODO: remove after fix https://github.com/ionic-team/ionic/issues/17450#issuecomment-504183616
      animated: false,
      duration: 5000,
      color: 'secondary',
      buttons: [{
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }],
      ...config
    };

    // dismiss old toast if any
    if (await this.toastCtrl.getTop()) await this.toastCtrl.dismiss();

    const toast = await this.toastCtrl.create(toastConfig);
    // HACK: adjust line height of toast shadow dom.
    toast.shadowRoot.querySelector('.toast-message').setAttribute('style', 'line-height: 1.4rem');
    toast.present();
  }

  public async showPromptAlert(header: string, placeholder: string): Promise<string> {
    return new Promise<string>(async resolve => {
      const alert = await this.alertCtrl.create({
        header,
        inputs: [
          {
            placeholder,
            name: 'name',
            type: 'text',
          },
        ],
        buttons: [
          {
            text: 'Abbrechen',
            role: 'cancel',
          }, {
            text: 'Ok',
            handler: ({ name }) => resolve(name || ''),
          }
        ]
      });

      await alert.present();
    });
  }

  public async showPhoneLogin(phone: string): Promise<boolean> {
    const modal = await this.modalCtrl.create({
      component: PhoneLoginModalComponent,
      componentProps: { phone, help: () => this }
    });

    const resultPromise = modal.onDidDismiss();

    await modal.present();

    const { data }  = await resultPromise as PhoneLoginResult;
    return !!data && data.success
  }
}
